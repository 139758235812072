import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 810.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,810.000000) scale(0.100000,-0.100000)">

<path d="M5578 5755 c-55 -18 -96 -64 -105 -119 -8 -48 21 -120 58 -146 l29
-21 -21 -28 c-11 -15 -23 -47 -26 -69 l-6 -42 -566 0 c-428 0 -570 -3 -579
-12 -9 -9 -12 -184 -12 -728 0 -789 -1 -773 63 -894 41 -78 135 -172 212 -213
123 -64 108 -63 902 -61 l718 3 3 652 2 651 39 -5 c54 -7 107 13 138 53 l27
33 23 -21 c36 -34 127 -37 173 -6 41 27 70 79 70 124 0 49 -44 212 -78 286
-68 151 -170 277 -304 379 -115 86 -203 129 -353 171 -96 27 -340 34 -407 13z
m289 -66 c309 -42 569 -235 704 -524 43 -90 84 -245 74 -276 -10 -31 -46 -59
-76 -59 -45 0 -68 28 -84 101 -62 298 -293 532 -588 595 -47 10 -113 14 -190
12 -109 -2 -119 0 -139 19 -28 28 -28 78 0 105 35 35 158 47 299 27z m76 -283
c175 -59 306 -171 381 -328 47 -97 72 -193 62 -234 -6 -23 -51 -54 -79 -54
-33 0 -66 40 -77 94 -29 139 -125 269 -249 335 -87 46 -150 61 -262 61 -110 0
-133 13 -133 76 0 66 48 86 191 79 62 -3 117 -13 166 -29z m-36 -270 c107 -49
201 -169 219 -282 8 -54 -26 -97 -76 -97 -37 0 -80 39 -80 74 0 27 -27 78 -59
112 -43 47 -98 70 -176 73 -62 2 -73 6 -93 30 -29 33 -24 79 11 108 35 29 172
19 254 -18z m-123 -250 c45 -19 66 -52 66 -104 0 -63 -40 -102 -105 -102 -38
0 -52 5 -76 29 -54 55 -37 146 34 176 40 18 41 18 81 1z m-589 -296 c91 -175
122 -227 130 -216 6 7 60 108 120 224 l110 210 10 -51 c25 -125 126 -180 258
-141 16 5 17 -15 17 -320 l0 -326 -105 0 -105 0 -2 260 -3 259 -122 -222 -122
-222 -63 -3 -63 -2 -125 229 -125 229 -3 -264 -2 -264 -105 0 -105 0 0 418 c0
230 3 422 7 425 3 4 67 7 142 7 l136 0 120 -230z"/>
<path d="M4250 2980 l0 -140 35 0 35 0 0 88 0 87 42 -77 c56 -103 69 -103 125
0 l42 77 1 -87 0 -88 35 0 35 0 0 140 0 140 -48 0 -48 0 -34 -71 c-19 -39 -38
-73 -42 -75 -4 -3 -24 29 -45 71 l-37 75 -48 0 -48 0 0 -140z"/>
<path d="M4660 3025 c0 -109 13 -146 60 -170 38 -20 115 -19 149 1 46 27 55
52 59 162 l4 102 -36 0 -36 0 0 -89 c0 -74 -3 -92 -21 -115 -25 -31 -61 -34
-89 -6 -17 17 -20 33 -20 115 l0 95 -35 0 -35 0 0 -95z"/>
<path d="M5042 3100 c-94 -57 -94 -183 0 -240 45 -27 141 -27 186 0 29 18 32
24 32 70 l0 50 -35 0 c-34 0 -35 -1 -35 -39 0 -35 -3 -40 -29 -46 -41 -9 -78
3 -101 32 -60 77 31 174 121 129 18 -9 33 -15 33 -14 1 2 10 13 20 25 19 23
19 23 -17 38 -52 22 -135 19 -175 -5z"/>
<path d="M5340 2988 c-35 -73 -66 -136 -68 -140 -2 -5 13 -8 32 -8 29 0 38 5
51 30 15 29 18 30 80 30 62 0 65 -1 80 -30 13 -25 22 -30 50 -30 19 0 35 2 35
4 0 2 -30 65 -66 140 -64 133 -67 136 -98 136 -31 0 -34 -3 -96 -132z m115 12
c22 -42 19 -50 -20 -50 -19 0 -35 2 -35 5 0 7 33 75 37 75 1 0 9 -13 18 -30z"/>
<path d="M5630 2981 l0 -141 35 0 34 0 3 83 3 82 39 -73 c51 -95 66 -94 121 6
l40 72 3 -85 3 -85 34 0 35 0 0 140 0 140 -47 0 -48 0 -39 -75 c-22 -41 -42
-72 -45 -69 -3 3 -22 36 -41 72 l-35 67 -47 3 -48 3 0 -140z"/>
<path d="M6081 3003 c-80 -162 -81 -163 -38 -163 29 0 37 5 47 30 12 29 14 30
80 30 66 0 68 -1 80 -30 11 -26 18 -30 50 -30 l38 0 -66 140 c-66 137 -68 140
-99 140 -32 0 -35 -4 -92 -117z m99 23 c0 -2 7 -20 15 -40 l15 -36 -39 0 -40
0 17 40 c9 22 20 40 25 40 4 0 7 -2 7 -4z"/>
<path d="M4210 2770 c0 -29 2 -30 45 -30 l45 0 0 -110 0 -110 35 0 35 0 0 109
0 110 43 3 c38 3 42 6 45 31 l3 27 -125 0 -126 0 0 -30z"/>
<path d="M4500 2660 l0 -140 110 0 110 0 0 25 c0 24 -2 25 -75 25 l-75 0 0 30
0 30 65 0 c63 0 65 1 65 25 0 24 -2 25 -65 25 l-65 0 0 30 0 30 75 0 75 0 0
30 0 30 -110 0 -110 0 0 -140z"/>
<path d="M4780 2660 l0 -140 100 0 c100 0 100 0 100 25 0 23 -3 24 -67 27
l-68 3 -3 113 -3 112 -29 0 -30 0 0 -140z"/>
<path d="M5020 2660 l0 -140 115 0 115 0 0 25 c0 25 -1 25 -80 25 l-80 0 0 30
0 30 70 0 c68 0 70 1 70 25 0 24 -2 25 -70 25 l-70 0 0 30 0 30 75 0 75 0 0
30 0 30 -110 0 -110 0 0 -140z"/>
<path d="M5352 2780 c-42 -26 -72 -76 -72 -120 0 -44 30 -94 72 -121 42 -26
148 -23 191 5 l28 19 -22 23 -21 22 -34 -20 c-44 -26 -95 -18 -124 19 -40 51
-17 114 48 132 30 9 44 7 76 -9 l40 -19 15 22 c20 28 20 28 -20 49 -47 25
-134 24 -177 -2z"/>
<path d="M5662 2780 c-99 -60 -94 -192 10 -246 42 -22 134 -18 175 7 90 56 86
191 -7 240 -48 26 -135 25 -178 -1z m143 -46 c35 -23 48 -68 31 -109 -17 -39
-42 -55 -86 -55 -43 0 -90 47 -90 90 0 69 86 113 145 74z"/>
<path d="M5960 2660 l0 -140 35 0 34 0 3 88 3 87 40 -77 c53 -103 69 -104 122
-7 l38 71 3 -81 3 -81 34 0 35 0 0 140 0 140 -44 0 -44 0 -42 -77 -42 -76 -40
76 -40 77 -49 0 -49 0 0 -140z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
